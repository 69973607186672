:root {
  --yellow-base: #ffc212;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.action-bar {
  height: 96px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.082);
  align-items: center;
  background: var(--snow-light);
}

/* DatePicker */

.bs-datepicker.datepicker-availabilities {
  top: 25vh !important;
  left: 90vw !important;
}

.bs-datepicker.datepicker-region-area {
  border-radius: 16px !important;
  position: fixed !important;
  top: 38vh;
  left: 60vw;
}

.bs-datepicker.datepicker-dashboard {
  right: 300px;
  top: 30px;
}

.bs-datepicker-head {
  background-color: var(--snow-light) !important;
  border-radius: 16px 16px 0px 0px !important;
}

.bs-datepicker-body {
  border: none !important;
}

.calendar-btn {
  background-color: var(--snow-dark);
  color: var(--gray-dark);
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 16px !important;
}

.calendar-clear-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border-radius: 8px;
  position: absolute;
  z-index: 1090;
  top: 300px;
  left: -110px;
}

.calendar-clear-btn:hover {
  background-color: var(--snow-base);
  color: var(--bluemix-dark) !important;
}

.calendar-clear-btn:active,
.calendar-clear-btn:focus {
  background-color: var(--blue-light);
  color: var(--blue-dark) !important;
  border: none;
}

.calendar-clear-btn:not(:disabled):not(.disabled).active,
.calendar-clear-btn:not(:disabled):not(.disabled):active,
.show > .detail-sidebar-button.dropdown-toggle,
.btn-link.focus,
.btn-link:focus {
  background-color: var(--blue-light);
  color: var(--blue-dark) !important;
  border: none;
}

.calendar-dashboard {
  position: relative;
  width: 40px;
  height: 40px;
}

.calendar-input {
  position: relative;
  top: 20%;
}

.bs-datepicker-head button {
  color: var(--blue-base) !important;
  font-family: 'Roboto' !important;
  font-weight: 500 !important;
}

.bs-datepicker {
  background: var(--snow-light) !important;
}

.bs-datepicker-body table td {
  color: var(--gray-dark) !important;
}

.bs-datepicker-body table th {
  color: var(--gray-dark) !important;
  font-weight: 700 !important;
}

.bs-datepicker-body table td span.is-other-month,
.bs-datepicker-body table td.is-other-month span {
  color: transparent;
}

/* DatePicker */

.modal-sm {
  max-width: 350px !important;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}

/* Scrollbar */

.panel {
  background-color: transparent;
  border: none;
}

.region-area-group .panel-heading.card-header {
  background-color: transparent;
  border: none;
  height: 50px;
}

.region-area:hover {
  background-color: var(--blue-base) !important;
  color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.panel-body.card-block.card-body {
  margin: 0;
  padding: 0;
}

.hide-show {
  display: block;
  position: sticky;
  margin-top: -33px;
  margin-left: 90%;
  width: 20px;
  justify-self: end;
}

.hide-show i {
  width: auto;
  height: auto;
  cursor: pointer;
}

.modal-dialog-centered {
  display: flex;
  align-self: center;
  justify-self: center;
  animation-duration: 5s !important;
}

.fade-modal {
  opacity: 0;
}

.map-search {
  position: absolute;
  top: 24px;
  right: 32px;
  z-index: 500;
  background-color: var(--snow-base);
  border: 0px;
  width: 224px;
  height: 48px;
  border-radius: 16px;
  padding: 0 40px 0 40px;
  background-image: url(/assets/icons/magnifying-glass.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 14px;
}

.form-search {
  background-color: var(--snow-base);
  border: 0px;
  border-radius: 16px;
  padding: 0px 40px 0 48px;
  background-image: url(/assets/icons/magnifying-glass.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 16px;
  background-size: 20px;
}

/* Caixa da imagem quando ta vazia */
.box-img-list {
  width: 120px;
  height: 120px;
  border-radius: 16px;
  background-color: var(--snow-base);
  background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='16' ry='16' stroke='%237E7E80FF' stroke-width='2' stroke-dasharray='15%2c8' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
}

input.map-search:focus-visible {
  outline: thin;
}

.apexcharts-datalabels {
  display: none !important;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 500;
}

.region-map .leaflet-interactive:hover {
  stroke: var(--blue-base);
  fill: var(--blue-light);
}

.cursor-pointer {
  cursor: pointer;
}

accordion.panel-group.group {
  margin: 16px 1px;
  border-radius: 16px;
  display: flex;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(36, 36, 36, 0.1);
  background-color: white;
  padding: 16px;
}

accordion-group.panel-group.group.region-area > accordion-group.panel {
  padding: 0px;
}

accordion-group.region-area {
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(12, 12, 12, 0.048);
  border-radius: 8px;
}

.panel-heading.card-header.panel-enabled,
.panel-heading.card-header.panel-disabled {
  background-color: transparent;
  border: none;
  padding: 0;
}

.panel-open .panel-heading.card-header.panel-enabled {
  margin-bottom: 0px;
}

panel.acc-group > .panel-collapse.collapse {
  margin-top: -20px;
}

.panel.acc-group {
  padding: 0px 32px;
  padding-top: 16px;
  border-radius: 16px;
  background: white;
  box-shadow: 0px 4px 6px rgba(36, 36, 36, 0.1);
}

.leaflet-editing-icon {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: var(--blue-base);
  border: 1px solid var(--blue-dark);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.116);
}

.editable-polygon {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: green;
  border: 1px solid orange;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.116);
}

accordion-group.panel.hvd {
  margin: 16px 0;
  padding-top: 10px;
  box-shadow: 0px 0px 2px rgba(36, 36, 36, 0.281);
  border-radius: 8px;
}

accordion-group.panel.hvd.panel-open {
  box-shadow: none;
  margin-top: 0px;
  padding: 0;
}

img {
  object-fit: cover;
}

accordion.panel-group.group.list-tile {
  box-shadow: 0px 2px 5px rgba(36, 36, 36, 0.1);
}

.leaflet-top.leaflet-left {
  display: none;
}

.class-blue-round .leaflet-popup-content-wrapper,
.class-blue-round .leaflet-popup-tip {
  background: transparent !important;
  box-shadow: none !important;
}

.class-blue-round a.leaflet-popup-close-button {
  display: none !important;
}

bar.progress-bar-success.bg-success.progress-bar {
  background-color: var(--greenmix-light) !important;
}

.icon-marker-catalog {
  font-size: 32px;
  color: white;
  position: absolute;
  top: 10px;
  right: 2px;
}

.svg-marker-catalog {
  width: 32px;
  height: 32px;
  position: absolute;
}

.svg-marker-catalog-cam {
  width: 32px;
  height: 36px;
  position: absolute;
}

.ab-marker-catalog {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -5px;
  right: -5px;
}

.vertical-marker-catalog {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -5px;
  right: -3px;
}

.btn-popup,
.btn-popup:hover {
  background: transparent;
  text-decoration: none;
  color: var(--blue-base) !important;
}

.btn-popup:not(:disabled):not(.disabled).active,
.btn-popup:not(:disabled):not(.disabled):active,
.show > .btn-popup.dropdown-toggle,
.btn-link.focus,
.btn-link:focus {
  background-color: transparent;
  border-color: transparent;
  color: var(--blue-base) !important;
}

.btn-danger {
  opacity: 1 !important;
  pointer-events: all !important;
}

.toast {
  width: 460px !important;
}

.toast-title {
  font-family: 'Roboto';
  font-size: 15px;
  line-height: 150%;
  color: var(--gray-dark);
  font-weight: 400;
  padding-top: 8px;
}

.toast-icon {
  left: -40px;
}

.popover {
  border-radius: 16px;
  background-color: var(--gray-dark);
}

.popover-body {
  padding: 0;
}

.icon-cluster {
  width: 40px !important;
  height: 40px !important;
  background-color: var(--gray-light);
  backdrop-filter: blur(8px);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -15px;
  right: -15px;
}

.map-info-window-group {
  position: relative;
}

.map-info-window-group > .plate {
  position: absolute;
  left: 32px;
  bottom: 2px;
  width: 24px;
  height: 24px;
}

.map-info-window-group > .plate.p1 > img,
.map-info-window-group > .plate.p2:hover > img,
.map-info-window-group > .plate.p3:hover > img {
  filter: drop-shadow(0 0 2px var(--blue-base));
}

.map-info-window-group > .info.p2,
.map-info-window-group > .info.p3 {
  display: none;
}

.map-info-window-group > .plate.p2:hover ~ .plate.p1 > img,
.map-info-window-group > .plate.p3:hover ~ .plate.p1 > img {
  filter: none !important;
}

.map-info-window-group > .plate.p2:hover ~ .info.p1,
.map-info-window-group > .plate.p3:hover ~ .info.p1 {
  display: none;
}

.map-info-window-group > .plate.p2:hover ~ .info.p2,
.map-info-window-group > .plate.p3:hover ~ .info.p3 {
  display: block;
}

.popover-body {
  display: flex;
}

.path-marker-internal {
  width: 40px;
  height: 57px;
  display: flex;
  justify-content: center;
}

.path-marker-internal-edition {
  width: 50px;
  height: 65px;
  display: flex;
  justify-content: center;
}

.path-internal-edition {
  position: absolute;
  color: white;
  bottom: 11px;
  font-size: 15px;
}

.path-internal {
  position: absolute;
  color: white;
  bottom: 9px;
  font-size: 15px;
}

.path-state {
  position: absolute;
  color: white;
  bottom: -1px;
  font-size: 10px;
}

.internal-caption > span {
  position: absolute;
  font-family: 'Roboto';
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  z-index: 10;
}

.R-14-32 > span {
  font-size: 10px;
  width: 30px;
  height: 28px;
}

.R-14-32-popover > span {
  font-size: 10px;
  width: 30px;
  height: 28px;
}

.R-14-40 > span {
  font-size: 13px;
  width: 38px;
  height: 34px;
}

.R-14-40-map > span {
  font-size: 13px;
  width: 37px;
  height: 37px;
}

.R-14-128 > span {
  font-size: 3em;
  width: 240px;
  height: 150px;
}

.R-14-160 > span {
  font-size: 3.3em;
  width: 240px;
  height: 230px;
}

.R-14-240 > span {
  font-size: 4.5em;
  width: 240px;
  height: 210px;
}

.R-15-32 > span {
  font-size: 10px;
  width: 28px;
  height: 32px;
}

.R-15-32-popover > span {
  font-size: 10px;
  width: 28px;
  height: 30px;
}

.R-15-40 > span {
  font-size: 14px;
  font-weight: 700;
  width: 34px;
  height: 40px;
}

.R-15-40-map > span {
  font-size: 14px;
  width: 34px;
  height: 40px;
}

.R-15-128 > span {
  font-size: 3em;
  width: 240px;
  height: 165px;
}

.R-15-160 > span {
  font-size: 3em;
  width: 230px;
  height: 240px;
  right: 10px;
}

.R-15-240 > span {
  font-size: 4.1em;
  width: 240px;
  height: 240px;
}

.R-16-32 > span {
  font-size: 10px;
  width: 31px;
  height: 32px;
}

.R-16-32-popover > span {
  top: 0.5em;
  font-size: 12px;
  width: 32px;
  text-align: center;
}

.R-16-40 > span {
  font-size: 14px;
  width: 40px;
  height: 38px;
}

.R-16-40-map > span {
  font-size: 14px;
  width: 40px;
  height: 38px;
}

.R-16-128 > span {
  font-size: 3em;
  width: 240px;
  height: 160px;
}

.R-16-160 > span {
  font-size: 3.5em;
  width: 240px;
  height: 240px;
}

.R-16-240 > span {
  font-size: 4em;
  width: 240px;
  height: 230px;
}

.R-17-32 > span {
  font-size: 10px;
  width: 30px;
  height: 28px;
}

.R-17-32-popover > span {
  font-size: 10px;
  width: 30px;
  height: 28px;
  top: -2px;
}

.R-17-40 > span {
  font-size: 11px;
  width: 38px;
  height: 36px;
}

.R-17-40-map > span {
  font-size: 11px;
  width: 38px;
  height: 34px;
}

.R-17-128 > span {
  font-size: 2.5em;
  width: 240px;
  height: 150px;
}

.R-17-160 > span {
  font-size: 3em;
  width: 221px;
  height: 225px;
}

.R-17-240 > span {
  font-size: 3.5em;
  width: 240px;
  height: 200px;
}

.R-18-32 > span {
  top: 6px;
  font-size: 7px;
  width: 28px;
  height: 32px;
}

.R-18-32-popover > span {
  top: 5px;
  font-size: 7px;
  width: 28px;
  height: 32px;
}

.R-18-40 > span {
  top: 6px;
  font-size: 8px;
  width: 35px;
  height: 40px;
}

.R-18-40-map > span {
  top: 6px;
  font-size: 8px;
  width: 35px;
  height: 40px;
}

.R-18-128 > span {
  font-size: 1.3em;
  width: 225px;
  height: 208px;
}

.R-18-160 > span {
  font-size: 2em;
  width: 208px;
  height: 240px;
  top: 29px;
  left: 8px;
}

.R-18-240 > span {
  font-size: 2.4em;
  width: 215px;
  height: 240px;
  top: 35px;
  right: 25px;
}

.R-19-32 > span {
  font-size: 12px;
  width: 32px;
  height: 28px;
}

.R-19-32-popover > span {
  font-size: 12px;
  width: 32px;
  height: 28px;
}

.R-19-40 > span {
  font-size: 14px;
  width: 40px;
  height: 40px;
  top: -3px;
}

.R-19-40-map > span {
  font-size: 14px;
  width: 40px;
  height: 40px;
  top: -3px;
}

.R-19-128 > span {
  font-size: 3.5em;
  width: 240px;
  height: 150px;
}

.R-19-160 > span {
  font-size: 3.5em;
  width: 240px;
  height: 230px;
}

.R-19-240 > span {
  font-size: 4.2em;
  width: 240px;
  height: 210px;
}

.A-37-32 > span {
  font-size: 10px;
  width: 28px;
  height: 32px;
}

.A-37-32-popover > span {
  font-size: 10px;
  width: 28px;
  height: 32px;
}

.A-37-40 > span {
  font-size: 14px;
  width: 34px;
  height: 40px;
}

.A-37-40-map > span {
  font-size: 14px;
  width: 34px;
  height: 40px;
}

.A-37-240 > span {
  font-size: 4.5em;
  width: 230px;
  height: 240px;
}

.A-37-128 > span {
  font-size: 3em;
  width: 230px;
  height: 165px;
}

.A-37-160 > span {
  font-size: 2.8em;
  width: 208px;
  height: 240px;
  right: 18px;
}

.A-38-32 > span {
  font-size: 10px;
  width: 31px;
  height: 32px;
}

.A-38-32-popover > span {
  font-size: 10px;
  width: 31px;
  height: 32px;
}

.A-38-40 > span {
  font-size: 14px;
  width: 40px;
  height: 38px;
}

.A-38-40-map > span {
  font-size: 14px;
  width: 40px;
  height: 38px;
}

.A-38-128 > span {
  font-size: 2.5em;
  width: 240px;
  height: 165px;
}

.A-38-160 > span {
  font-size: 3em;
  width: 208px;
  height: 240px;
}

.A-38-240 > span {
  font-size: 4em;
  width: 240px;
  height: 240px;
}

.A-46-32 > span {
  font-size: 10px;
  width: 29px;
  height: 32px;
}

.A-46-32-popover > span {
  font-size: 10px;
  width: 29px;
  height: 32px;
}

.A-46-40 > span {
  font-size: 13px;
  width: 36px;
  height: 38px;
}

.A-46-40-map > span {
  font-size: 13px;
  width: 36px;
  height: 38px;
}

.A-46-128 > span {
  font-size: 2.5em;
  width: 228px;
  height: 165px;
}

.A-46-160 > span {
  font-size: 2.8em;
  width: 240px;
  height: 240px;
  right: 3px;
}

.A-46-240 > span {
  font-size: 4.5em;
  width: 240px;
  height: 224px;
}

.A-47-32 > span {
  font-size: 9px;
  width: 30px;
  text-align: center;
  height: 28px;
}

.A-47-32-popover > span {
  font-size: 9px;
  width: 30px;
  height: 28px;
}

.A-47-40 > span {
  font-size: 11px;
  width: 38px;
  height: 36px;
}

.A-47-40-map > span {
  font-size: 11px;
  width: 38px;
  height: 34px;
}

.A-47-128 > span {
  font-size: 2em;
  width: 235px;
  height: 150px;
}

.A-47-160 > span {
  font-size: 2.5em;
  width: 240px;
  height: 225px;
}

.A-47-240 > span {
  font-size: 3.5em;
  width: 236px;
  height: 215px;
}

.A-48-32 > span {
  top: 5px;
  font-size: 7px;
  width: 27px;
  height: 32px;
}

.A-48-32-popover > span {
  top: 5px;
  font-size: 7px;
  width: 27px;
  height: 32px;
}

.A-48-40 > span {
  top: 5px;
  font-size: 8px;
  width: 35px;
  height: 40px;
}

.A-48-40-map > span {
  top: 5px;
  font-size: 8px;
  width: 35px;
  height: 40px;
}

.A-48-128 > span {
  font-size: 1.5em;
  width: 225px;
  height: 198px;
}

.A-48-160 > span {
  font-size: 2em;
  width: 160px;
  height: 290px;
  right: 50px;
}

.A-48-240 > span {
  font-size: 3em;
  width: 210px;
  height: 240px;
  top: 30px;
}

.sign-cam-24 {
  width: 24px !important;
  height: 36px !important;
}

.sign-cam-32 {
  width: 32px !important;
  height: 48px !important;
}

.sign-cam-40 {
  width: 40px !important;
  height: 60px !important;
}

.sign-cam-240 {
  width: 240px !important;
  height: 360px !important;
}

.complement-information-40 {
  display: flex;
  justify-content: center;
  height: 48px;
  width: 40px;
}

.complement-additional-40 {
  width: 40;
  height: 40;
  position: absolute;
  bottom: 10px;
}

.complement-incorporated-40 {
  width: 40px;
  height: 60px;
  position: absolute;
  bottom: 8px;
}

accordion-group.card-none > div > .card-header {
  padding: 0 !important;
  margin-bottom: 0 !important;
  background-color: transparent !important;
  border-bottom: none !important;
}

/* toggle de fechar o filtro do mapa */
.button-close-filter {
  position: absolute;
  width: 20px;
  height: 64px;
  left: 274px;
  top: 40px;
  background-color: white;
  border-radius: 0 8px 8px 0;
  box-shadow: 4px 0px 9px rgba(36, 36, 36, 0.35);
  cursor: pointer;
}

.apexcharts-legend-marker {
  margin-right: 8px !important;
}

.apexcharts-legend-series {
  margin: 2px 8px !important;
}

.apexcharts-legend {
  top: -4px !important;
}

.new-element {
  animation-name: newElementAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background-color: white;
}

@keyframes newElementAnimation {
  0% {
    bottom: 100px;
  }

  100% {
    bottom: 0px;
  }
}

.remove-element {
  animation-name: removeElementAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background-color: white;
  z-index: 300 !important;
}

@keyframes removeElementAnimation {
  0% {
    bottom: 0px;
  }

  100% {
    bottom: 100px;
  }
}

.remove-marker {
  animation-name: removeMarkerAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  z-index: 300 !important;
}

@keyframes removeMarkerAnimation {
  0% {
    bottom: 0px;
    transform: scale(1);
  }

  100% {
    bottom: 100px;
    transform: scale(0.5);
  }
}

.info-tooltip {
  width: 480px;
  margin-top: 16px;
  margin-right: 380px;
}

.info-tooltip .tooltip-inner {
  background: white;
  border-bottom-color: white;
  padding: 0;
  text-align: start;
  border-radius: 16px;
}

.tooltip.show {
  opacity: 1 !important;
}

.info-tooltip .tooltip-arrow::before {
  border-bottom-color: white;
}

#customPieChart bs-tooltip-container {
  display: none !important;
}

.tooltip-new {
  background-color: var(--gray-dark);
  color: white;
}

.leaflet-tooltip-top {
  background-color: var(--gray-dark) !important;
  border-radius: 8px !important;
}

.leaflet-tooltip-top:before {
  border-top-color: var(--gray-dark) !important;
}

.circle-exclamation {
  background-color: var(--redmix-light);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.weight-500 {
  font-weight: 500 !important;
}

.ellipsis-cell {
  width: 24px !important;
  border-radius: 8px !important;
}

/* Precisa por o tamanho e a largura correta no lugar */
.text-overflow {
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-dashboard-overflow {
  white-space: nowrap;
  width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownSearch {
  width: 18vw;
  max-height: 176px;
  min-height: 64px;
  overflow: auto;
  z-index: 900;
  background-color: white;
  position: absolute;
  right: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(36, 36, 36, 0.35);
}

.dropdownItem {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: var(--gray-dark);
  flex: none;
  flex-grow: 0;
  padding: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.dropdownItem:hover {
  background: #f1f1f4;
  color: var(--blue-base);
}

.popover.task-popover {
  height: auto !important;
  min-width: 400px !important;
  border-radius: 16px;
  background-color: white;
  z-index: 1900;
  padding: 16px;
  box-shadow: 0px 6px 9px rgba(36, 36, 36, 0.35);
  border: none !important;
}

.popover.cluster-popover {
  height: auto !important;
  min-width: 400px !important;
  border-radius: 16px;
  background-color: var(--snow-dark);
  z-index: 1700;
  padding: 8px;
  box-shadow: 0px 6px 9px rgba(36, 36, 36, 0.35);
  border: none !important;
}

.popover.caption-popover {
  height: auto !important;
  border-radius: 16px;
  background-color: white;
  z-index: 1700;
  padding: 24px;
  box-shadow: 0px 6px 9px rgba(36, 36, 36, 0.35);
  border: none !important;
  margin-right: 32px !important;
}

.popover .arrow {
  display: none;
}

.diagram-row ~ .popover .arrow {
  display: block;
}

a.dropdown-item {
  cursor: pointer;
}

.disabled-li {
  pointer-events: none !important;
  color: var(--gray-base) !important;
}

.public-acc-header {
  background: #ffffff;
  /* Mobile - Elevações/Mobile - Shadow 2 */

  box-shadow: 0px -0.5px 1.41px rgba(0, 0, 0, 0.1), 0px 1px 1.41px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.dashboard-tooltip {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--gray-dark);
  color: white;
  padding: 8px;
  border-radius: 8px;
  gap: 2px;
}

.popover-team {
  min-width: 300px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.popover-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popover-flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

.image-rounded {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

/* Classes para cores de tarefas de acordo com status e prioridade */
.HIGH {
  color: var(--red-base);
}

.MEDIUM {
  color: var(--orange-base);
}

.LOW {
  color: var(--green-base);
}

.TO_START {
  color: #ffc212;
}

.STARTED {
  color: var(--blue-base);
}

.PAUSED {
  color: var(--orange-base);
}

.COMPLETED,
.CONCLUDED {
  color: var(--green-base);
}

.CANCELED {
  color: var(--red-base);
}

/* Tipo HVD em volta de uma box */
.type-sign-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 15px;
  background: #e4e4e5;
  border-radius: 8px;
}

.type-sign-text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

.progress-task-bar {
  border-radius: 8px;
  height: 8px;
  width: 100%;
}

.progress-task-bar-snow {
  background: var(--snow-base);
  border-radius: 8px;
  height: 8px;
  width: 100%;
}

.progress-task-bar-green {
  background: var(--green-base);
  border-radius: 8px;
  height: 8px;
  width: 100%;
}

.number-task-popover {
  border-radius: 50%;
  background-color: var(--blue-base);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  top: -8px;
  right: -8px;
  color: white;
}

.detail-sidebar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border-radius: 8px;
}

.detail-sidebar-button:hover {
  background-color: var(--snow-base);
  color: var(--bluemix-dark) !important;
}

.detail-sidebar-button:active,
.detail-sidebar-button:focus {
  background-color: var(--blue-light);
  color: var(--blue-dark) !important;
}

.detail-sidebar-button:not(:disabled):not(.disabled).active,
.detail-sidebar-button:not(:disabled):not(.disabled):active,
.show > .detail-sidebar-button.dropdown-toggle,
.btn-link.focus,
.btn-link:focus {
  background-color: var(--blue-light);
  color: var(--blue-dark) !important;
}

.yaxis-label {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto' !important;
  word-break: break-all !important;
}

.custom-chart-option {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(36, 36, 36, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  padding-top: 8px;
}

.button-icon-transparent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: transparent;
  border-radius: 16px;
  padding: 16px !important;
  color: var(--gray-dark);
  font-size: 16px !important;
}

.button-icon-transparent:hover {
  background-color: var(--blue-base) !important;
  color: white !important;
  border: 0 !important;
}

.button-icon-transparent:active,
.button-icon-transparent:focus {
  background-color: var(--blue-dark) !important;
  color: white !important;
  border: 0 !important;
}

.button-icon-transparent:disabled {
  pointer-events: none;
  color: var(--gray-light) !important;
}

.calendar-clear-btn-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border-radius: 8px;
  position: absolute;
  z-index: 1090;
  top: 8px;
  left: -140px;
}

bs-dropdown-container {
  z-index: 1090 !important;
}

.bg-info {
  background-color: var(--blue-base) !important;
}

.apexcharts-datalabel-label {
  word-break: break-all;
}

.custom-chart.theme-dark-blue {
  border-radius: 16px;
  padding-top: 80px;
}

.image-team-rounded {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
}

.marker-street-number {
  color: white !important;
  font-size: 1.5rem !important;
  font-weight: 600;
  line-height: 2rem;
  margin-top: -20px;
  background-color: var(--blue-dark);
  border-radius: 50%;
  width: 2rem;
}

.sign-image-popover {
  margin-right: -10px;
  height: 130px;
  margin-left: -10px;
  margin-top: -4px;
}

.image-popover {
  width: 100%;
  height: 120px;
  border-radius: 8px;
}

.occurrence-map-icon {
  color: var(--orange-base);
  font-size: 18px !important;
  position: absolute;
  bottom: 0px;
  right: -5px;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff,
    -1px 1px 0 #fff;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  left: calc(100% - 85px) !important;
}

button.gm-svpc > div > img {
  object-fit: contain;
}

.gm-iv-address {
  position: absolute;
  top: 100px;
  right: 57px;
}

.gm-iv-container.gm-iv-small-container {
  position: absolute;
  top: 100px;
  right: 30px;
}

.gm-iv-address-link {
  top: 40px !important;
}

.gm-iv-vertical-separator {
  top: 0px !important;
}

.gm-iv-marker {
  top: 18px !important;
}

div > .gm-style > div > div > .gmnoprint > .gm-style-cc > div > button {
  display: none !important;
}

.gm-style-iw {
  padding: 12px !important;
}

.gm-style-iw > div {
  overflow: hidden !important;
}

.google-maps-popup {
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 300px;
}

.dropdown-search-filter {
  width: 600px;
  max-height: 176px;
  min-height: 64px;
  overflow: auto;
  z-index: 900;
  background-color: white;
  position: absolute;
  right: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(36, 36, 36, 0.35);
}

.gmnoprint > div > button > span > div {
  width: 20px !important;
  height: 20px !important;
}

.gmnoprint > div:first-child > button > span > div > img {
  width: 20px !important;
  top: -156px !important;
}

.gmnoprint > div:nth-child(2) > button > span > div > img {
  width: 20px !important;
  top: -16px !important;
}

.gmnoprint > div:nth-child(3) > button > span > div > img {
  width: 20px !important;
  top: -55px !important;
}

body > iframe {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.marker-background-image {
  background-size: cover;
}

.marker-complement-additional {
  background-size: cover;
}

.marker-complement-incorporated {
  background-size: cover;
  left: 0px;
}

.complement-marker-span {
  position: absolute;
}

.multiline-ellipsis {
  display: -webkit-box; /* Garante a compatibilidade com WebKit */
  -webkit-line-clamp: 2; /* Define o número de linhas a exibir */
  -webkit-box-orient: vertical; /* Define a orientação das linhas */
  overflow: hidden; /* Esconde o conteúdo que ultrapassa o limite */
  text-overflow: ellipsis; /* Aplica o "..." no fim */
}
